<template>
  <div class="auth-screen driver-signup-main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="auth-fold">
            <div class="auth-header">
              <a href="" class="auth-link active" style="text-transform: none;">Sign up</a>
              <!-- <a href="login.html" class="auth-link">Log in</a> -->
            </div>
            <div class="auth-body">
              <div class="auth-left">
                <h2>Join</h2>
                <figure>
                  <img :src="require('@assets/images/white-logo.svg')" alt="" />
                </figure>
                <p>Sign up to find work in your area.</p>
                <p>Drivers2u is the the largest global directory of drivers!</p>
              </div>
              <div class="auth-right">
                <!-- <div class="login-access">
                  <h5>Sign up using</h5>
                  <a
                    @click.prevent="socialLoginAuthProvider('google')"
                    :style="isSignUpWithSocialLogin ? 'pointer-events:none' : 'cursor: pointer'"
                  >
                    <img :src="require('@assets/images/google.svg')" alt="" />
                  </a>
                  <a
                    @click.prevent="socialLoginAuthProvider('facebook')"
                    :style="isSignUpWithSocialLogin ? 'pointer-events:none' : 'cursor: pointer'"
                  >
                    <img
                      :src="require('@assets/images/facebook.svg')"
                      :style="isSignUpWithSocialLogin ? 'pointer-events:none' : 'cursor: pointer'"
                    />
                  </a>
                  <a href="javascript:;">
                    <img :src="require('@assets/images/apple.svg')" alt="" />
                  </a>
                </div> -->
                <!-- <div class="login-title">Sign up using your email</div> -->
                <form @submit.prevent="driverSignup">
                  <div class="auth-form">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="First name"
                        v-model.trim="$v.name.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <span class="error" v-if="$v.name.$error"> first name field is required</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Last name"
                        v-model.trim="$v.lastname.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <span class="error" v-if="$v.name.$error"> last name field is required </span>
                    </div>
                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        v-model.trim="$v.email.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <span class="error" v-if="$v.email.$error"> email field is required </span>
                    </div>
                    <div class="form-group">
                      <VuePhoneNumberInput
                        v-model="$v.mobile.$model"
                        :disabled="isPhoneVerified"
                        :default-country-code=defaultCountryCode
                        @update="changeMobile"
                      />
                      <!-- <div class="country">
                        <div id="country" class="select">
                          <img :src="require('@assets/images/AU.png')" alt="" />
                        </div>
                      </div>
                      <input
                        type="number"
                        class="form-control contact-number"
                        placeholder="Phone Number"
                        v-model.trim="$v.mobile.$model"
                        :readonly="isPhoneVerified"
                      /> -->
                      <span class="error" v-if="$v.mobile.$error">
                        phone number field is required </span
                      ><br />
                      <span class="error" v-if="isPhoneValidError"> please enter valid phone </span>
                      <a
                        class="btn btn-primary verify-send"
                        :style="isPhoneVerified ? 'pointer-events: none' : ''"
                        @click="sendOTP()"
                      >
                        Send OTP
                      </a>
                      <v-otp-input
                        v-if="enterCode"
                        input-classes="otp-input"
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        separator=""
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                      />
                      <span class="error" v-if="$v.otp.$error && !$v.otp.required"
                        >OTP field is required .</span
                      >
                      <span class="error" v-if="$v.otp.$error && !$v.otp.minLength"
                        >OTP must have at least {{ $v.otp.$params.minLength.min }} letters.</span
                      >
                      <a class="btn btn-primary verify-otp" @click="verifyOTP()" v-if="enterCode"
                      :style="isOTPSubmit ? 'pointer-events: none' : ''">
                        Verify OTP
                      </a>
                    </div>
                    <div class="form-group">
                      <!-- <input
                        style="display:none"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        v-model.trim="$v.email.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <span class="error" v-if="$v.email.$error">
                         Email field is required </span> -->
                      <input style="visibility:hidden" class="form-control"/>
                    </div>
                    <div class="form-group">
                      <input
                        style="margin-top: -51px"
                        :type="passwordType"
                        class="form-control"
                        placeholder="Password"
                        v-model.trim="$v.password.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <div class="icons">
                        <img :src="require('@assets/images/eye.svg')" class="eye-class eye-login"
                        alt="" @click="showPassword"/>
                      </div>
                      <password v-model="$v.password.$model" :strength-meter-only="true"
                      class="driver-signup-border"/>
                      <span class="error" v-if="$v.password.$error && !$v.password.required"
                        >password field is required .</span
                      >
                      <!-- <span class="error" v-if="$v.password.$error && !$v.password.minLength"
                        >password must have at least
                        {{ $v.password.$params.minLength.min }} letters.</span
                      >
                      <span class="error" v-if="$v.password.$error && !$v.password.maxLength">
                        password must have minimum 8 maximum
                        {{ $v.password.$params.maxLength.max }} letters.
                      </span>
                      <span class="error" v-if="$v.password.$error && !$v.password.valid">
                        password contains atleast one uppercase,
                        one lowercase,
                        one number and one special chacter
                    </span> -->
                    <span class="error"
                      v-if="$v.password.$error &&
                            !$v.password.minLength ||
                            !$v.password.maxLength ||
                            !$v.password.valid"
                    >
                      Password must be between 8 and 16 characters long,
                      and contain at lease one of each of the following:
                      lowercase letters, numbers, and special characters.
                    </span>
                    </div>
                    <div class="form-group">
                      <input
                        :type="confirmPasswordType"
                        class="form-control"
                        placeholder="Confirm password"
                        v-model.trim="$v.password_confirmation.$model"
                        :readonly="isFormSubmit || isSignUpWithSocialLogin"
                      />
                      <div class="icons">
                        <img v-if="bothPasswordMatched" :src=
                        "require('@assets/images/password_matched.svg')" class="eye-class"
                        alt=""/>
                      <img :src="require('@assets/images/eye.svg')" class="eye-class eye-login"
                      alt="" @click="showConfirmPasswordType"/>
                      </div>
                      <span
                        class="error"
                        v-if="$v.password_confirmation.$error && !$v.password_confirmation.required"
                      >
                        confirm password field is required .</span
                      >
                      <span
                        class="error"
                        v-if="
                          $v.password_confirmation.$error && !$v.password_confirmation.minLength
                        "
                      >
                        confirm password must have at least
                        {{ $v.password_confirmation.$params.minLength.min }} letters.</span
                      ><br />
                      <span class="error" v-if="$v.password.$error && !$v.password.maxLength">
                        password must have minimum 8 maximum
                        {{ $v.password.$params.maxLength.max }} letters.
                        </span><br />
                      <span
                        class="error"
                        v-if="
                          $v.password_confirmation.$error &&
                          !$v.password_confirmation.sameAsPassword
                        "
                      >
                        confirm password must be same as password .
                      </span>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        checked
                        v-model.trim="$v.agreewithTerms.$model"
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        By clicking Signup button,<br />
                        I agree to the terms of service & privacy policy
                      </label>
                    </div>
                    <span class="error" v-if="$v.agreewithTerms.$error">
                      please accept terms of service & privacy policy </span
                    ><br />
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="isFormSubmit || !isPhoneVerified"
                    >
                      <span
                        v-if="isFormSubmit"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span v-if="!isFormSubmit" style="text-transform: none;">Sign up</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {
  required, sameAs, minLength, maxLength,
}
from 'vuelidate/lib/validators';

/* eslint-disable no-unused-vars */
import OtpInput from '@bachdgvn/vue-otp-input';
import Password from '@/views/pages/PasswordStrengthComponents/PasswordStrength.vue';

import useJwt from '@/auth/jwt/useJwt';

export default {
  name: 'DriverSignup',
  components: { 'v-otp-input': OtpInput, VuePhoneNumberInput, Password },
  data() {
    return {
      name: '',
      lastname: '',
      email: '',
      mobile: '',
      countryCode: '+61',
      password: '',
      password_confirmation: '',
      agreewithTerms: '',
      otp: '',
      isFormSubmit: false,
      isPhoneVerified: false,
      enterCode: false,
      isPhoneValid: false,
      isPhoneValidError: false,
      isOTPSubmit: false,
      isSignUpWithSocialLogin: false,
      passwordType: 'password',
      confirmPasswordType: 'password',
      defaultCountryCode: 'AU',
    };
  },
  validations: {
    name: {
      required,
    },
    lastname: {
      required,
    },
    email: {
      required,
    },
    mobile: {
      required,
    },
    otp: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(16),
    },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(16),
      valid(value) {
        // const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsLowercase && containsNumber && containsSpecial;
      },
    },
    password_confirmation: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('password'),
    },
    agreewithTerms: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  created() {
    this.ipLookUp();
  },
  computed: {
    bothPasswordMatched() {
      return (
        this.password.length > 7 && this.password === this.password_confirmation
      );
    },
  },
  methods: {
    ipLookUp() {
      this.$http.get('http://ip-api.com/json')
        .then((response) => {
          if (response && response.data && response.data.countryCode) {
            this.defaultCountryCode = response.data.countryCode;
          }
        })
        .catch((data, status) => {
        });
    },
    driverSignup() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isFormSubmit = true;

        this.$http
          .post('driverRegister', {
            name: this.name,
            last_name: this.lastname,
            email: this.email,
            mobile: this.mobile.replace(/ /g, ''),
            password: this.password,
            password_confirmation: this.password_confirmation,
            countryCode: this.countryCode,
            userRole: 2,
          })
          .then((response) => {
            useJwt.setDriverRegisterToken(response.data.data.token);
            this.$router.push({ name: 'driver-signup-2' });
          })
          .catch((error) => {
            this.$toast.error(error.response.data.data.email[0]);
            this.$toast.error(error.response.data.data.password[0]);
            this.$refs.loginForm.setErrors(error.response.data.error);
          })
          .finally(() => {
            this.isFormSubmit = false;
          });
      }
    },
    sendOTP() {
      this.$v.mobile.$touch();
      this.$v.email.$touch();
      const checkPhoneIsValid = this.isPhoneValid;
      if (!checkPhoneIsValid) {
        this.isPhoneValidError = true;
      }
      if (!this.$v.mobile.$invalid && checkPhoneIsValid && !this.$v.email.$invalid) {
        this.$http
          .post('send_otp_on_number', {
            mobile: this.mobile.replace(/[^0-9]/g, ''),
            countryCode: this.countryCode,
            email: this.email,
          })
          .then((response) => {
            this.enterCode = true;
            this.$toast.open('OTP sent successfully', 'success');
          })
          .catch((error) => {
            // this.$toast.error(error.response.data.message);
            this.$toast.error(error.response.data.data.error);
          });
      }
    },
    verifyOTP() {
      this.$v.otp.$touch();
      this.$v.email.$touch();
      if (!this.$v.otp.$invalid && !this.$v.email.$invalid) {
        this.isOTPSubmit = true;
        this.$http
          .post('verify_otp', {
            mobile: this.mobile.replace(/[^0-9]/g, ''),
            countryCode: this.countryCode,
            verification_code: this.otp,
            email: this.email,
          })
          .then((response) => {
            // if user is signup using social media then verify number and redirect to driversignup2
            if (this.isSignUpWithSocialLogin) {
              this.$router.push({ name: 'driver-signup-2' });
            }
            this.isPhoneVerified = true;
            this.enterCode = false;
            this.isOTPSubmit = false;
            this.$toast.open('OTP verify successfully', 'success');
          })
          .catch((error) => {
            this.isOTPSubmit = false;
            this.$toast.error(error.response.data.data.error);
          });
      }
    },
    showPassword() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    showConfirmPasswordType() {
      if (this.confirmPasswordType === 'password') {
        this.confirmPasswordType = 'text';
      } else {
        this.confirmPasswordType = 'password';
      }
    },
    handleOnComplete(value) {
      this.otp = value;
    },
    handleOnChange(value) {
      this.otp = value;
    },
    changeMobile($event) {
      this.isPhoneValid = $event.isValid;
      this.isPhoneValidError = false;
      this.countryCode = `+${$event.countryCallingCode}`;
    },
    socialLoginAuthProvider(provider) {
      this.isFormSubmit = true;
      const userRole = 2;

      this.$toast.open({
        message: 'Please wait',
        type: 'info',
        duration: 6000,
        dismissible: true,
      });

      this.authProvider(provider, userRole).then((data) => {
        if (data) {
          const { token } = data;
          useJwt.setDriverRegisterToken(token);
          // const { token, ...userData } = data;
          // localStorage.setItem('userData', JSON.stringify(userData));
          if (data.flag === 'Update') {
            this.$router.push({ name: 'driver-download-app' });
          }
          if (data.flag === 'Create') {
            this.isSignUpWithSocialLogin = true;
            this.name = data.name;
            this.email = data.email;
            this.password = '******';
            this.password_confirmation = '******';
            this.$toast.open({
              message: 'Successfully signup.Please verify moblie number',
              type: 'success',
              duration: 10000,
              dismissible: true,
            });
            this.$router.push({ name: 'driver-signup-2' });
          }
        } else {
          this.isFormSubmit = false;
          this.$toast.error('Something went wrong');
        }
      });
    },
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #0dcaf0;
  text-align: center;
}
.otp-input:focus-visible {
  outline: none;
  box-shadow: none;
}
label.input-tel__label {
  margin-top: -16px;
  margin-left: -14px;
  color: #ffffff !important;
}
.input-tel__input{
  padding: 0 !important;
}
.input-tel__input::placeholder{
  color: rgba(255, 255, 255, 0.5) !important;
}

.country-selector__input {
  display: none;
}
.select-country-container {
  max-width: 81px;
}
.country-selector__label {
  display: none;
}
.input-tel__input {
  background-color: unset !important;
  border: unset !important;
  caret-color: dodgerblue;
  box-shadow: unset !important;
  color: #ffffff !important;
  font-size: 16px !important;
  cursor: default !important;
  caret-color: #ffffff !important;
}
.vue-phone-number-input {
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
.input-tel__label {
  color: rgba(250, 250, 250, 0.3) !important;
  cursor: default !important;
}
.country-selector__country-flag[data-v-46e105de]{
  top: 15px;
}
/* .vue-phone-number-input .select-country-container[data-v-19c9a1c7] {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
} */
.vue-phone-number-input .select-country-container[data-v-19c9a1c7] {
  width: 0 !important;
  min-width: 0 !important;
  max-width: 120px !important;
  flex: 0 0 60px;
}
</style>
